import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import YouTube from 'react-youtube';
import Layout from '../components/layout';
import Metadata from '../components/metadata';

const opts = {
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    rel: 0
  },
};

const HeaderContent = ({ showVideo, setShowVideo }) => (
  <>
    {showVideo && (
      <button className="close-video" onClick={() => setShowVideo(false)}><i className="fa fa-close" aria-hidden="true"></i> close video</button>
    )}
    <div className="video-container">
      <video className="video-fullwidth -background" playsInline autoPlay muted loop src="/assets/DanielCare-web-clip.mp4"></video>
      {showVideo && (
        <YouTube
          videoId="SAAOeoUNtv0"
          opts={opts}
          className="video-fullwidth -foreground"
          onReady={(event) => {
            window.gtag('event', {
              hitType: 'event',
              eventCategory: 'Videos',
              eventAction: 'play',
              eventLabel: 'DanielCare intro'
            })
  
            event.target.playVideo();
          }}
        />
      )}
    </div>
  </>
)

const FooterContent = () => (
  <div className="dc">
    <span className="logo dc">DanielCare Licensed Home Health Care</span>
    <div className="learnmore">
      <p>A licensed, Medicare-certified home health care agency.</p>
      <Link to="/about/" className="dc arrow"><strong>Learn more</strong> about<br/>DanielCare Home Health Care <i className="fa fa-arrow-circle-right"></i></Link>
    </div>
  </div>
)

const IndexPage = ({ data }) => {
  const { markdownRemark: page } = data;
  const { frontmatter: metadata } = page;
  const [showVideo, setShowVideo] = useState(false)

  return (
    <Layout
      headerContent={<HeaderContent showVideo={showVideo} setShowVideo={setShowVideo} />}
      footerContent={<FooterContent />}
      className="home"
    >
      <Metadata pageData={metadata} />
      <article>
        <h1>{metadata.pagetitle || metadata.title }</h1>
        <p>Whether you or your loved one is recuperating from an illness or injury, the most comfortable and convenient place to receive care is at home.</p>

        <button className="video-button" onClick={() => setShowVideo(true)}><i className="fa fa-play-circle" title="Play video"></i>Watch video to meet DanielCare</button>

        <div className="sidebar">
          <aside>
            <p>Based in <b>Stamford</b> and <b>Shelton</b> serving Fairfield and New Haven County, Connecticut.</p>
            <div className="phones">
              <a href="tel:203-658-8080" className="phone shelton"><i className="fa fa-phone" title="Phone"></i> 203.658.8080</a>
            </div>
          </aside>
        </div>
      </article>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default IndexPage;

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        pagetitle
      }
    }
  }
`;
